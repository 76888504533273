import { Link as GLink, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { Box, Flex, Link } from 'juhuui';
import React from 'react';

import Hero from '../components/hero';
import SEO from '../components/seo';
import Subheader from '../components/subheader';
import { ContainerOuter, MainContent } from '../styles';
import { getLocalizationObj } from '../utilities/getLocalizationObj';

interface CareersProps {
  data: GraphQLData;
}

export const CareersTemplate = ({ data, subheaderIntro }: CareersProps) => {
  const { t } = useTranslation();

  const { subheader, meta } = getLocalizationObj(data.strapi?.career) || {};
  const { title = 'Careers', description } = meta || {};

  return (
    <>
      <SEO description={description} title={title} />
      <Subheader intro={subheaderIntro || subheader} />
      <MainContent>
        <ContainerOuter
          css={{
            pb: [16, undefined, 0],
            margin: 'auto',
            maxW: 'var(--max-width)',
            px: 'var(--px)',
          }}
        >
          <iframe
            id="coveto_iframe"
            src="https://k58684.coveto.de/public/jobs/"
            width="100%"
          />
          <script
            src="https://k58684.coveto.de/jobs.js"
            type="text/javascript"
          />
        </ContainerOuter>
        {/* <Hero alt={hero?.alternativeText} src={hero?.file} /> */}
      </MainContent>
      {/* <CareersTemplate data={data} /> */}
    </>
  );
};
