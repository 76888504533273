import { graphql } from 'gatsby';
import React from 'react';

import { Layout } from '../../containers/layout';
import { CareersTemplate } from '../../pageTemplates/careers';

interface CareersProps {
  data: GraphQLData;
}

const Careers = ({ data }: CareersProps) => {
  return (
    <Layout footer={{ onlyNav: true }}>
      <CareersTemplate data={data} />
    </Layout>
  );
};

export const query = graphql`
  query CareersEN {
    strapi {
      career {
        localizations {
          subheader
          meta {
            title
            description
          }
          # hero {
          #   alternativeText
          #   url
          #   file {
          #     ...Image
          #   }
          # }
        }
      }
    }
  }
`;

export default Careers;
